import { Targeting } from '@sbt-web/adv';
import { RECENT_SEARCHES_KEY } from '@shared/constants';
import { getMacroOfCategoryID } from '@sbt-web/utils';
import type { RecentSearchOption } from '@sbt-web/search-autocomplete';
import type { AdTypes, CategoryID } from '@sbt-web/network/types';

export type HomepageTargetingProps = {
  targeting: Targeting;
};

const filtersMapping: Record<string, string> = {
  brand: 'cb',
  model: 'cm',
  fuel: 'fl',
  minRegDate: 'ys',
  maxRegDate: 'ye',
  minMileage: 'ms',
  maxMileage: 'me',
  carType: 'ct',
  gearbox: 'gr',
  emission: 'pl',
  vehicleStatus: 'cvs',
  qualitySeal: 'qltsf',
  priceFrom: 'ps',
  priceTo: 'pe',
  roomMin: 'rs', // Real estate
  roomMax: 're', // Real estate
  jobCategory: 'jc',
  contractType: 'cnt',
  workLevel: 'wl',
  degree: 'd',
  advertiserType: 'advt',
};

const typeFiltersMapping: Record<string, string> = {
  contractType: 'cnt',
  computerType: 'pt',
  audioVideoType: 'avt',
  phoneType: 'pht',
  bicycleType: 'bkt',
  sportType: 'st',
  bookType: 'bot',
  clothingType: 'clt',
  roomType: 'rt',
  motorcycleType: 'mt',
  caravanType: 'crt',
  carType: 'ct',
  shipType: 'bt',
  commercialVehicleType: 'cvt',
  serviceType: 'set',
  hobbyType: 'ht',
  officeType: 'ot',
};

const buildType = (
  filtersLabels: Record<string, string | undefined> | undefined = {}
): string | undefined => {
  const key = Object.values(typeFiltersMapping).find(
    (key) => filtersLabels?.[key]
  );
  return key ? filtersLabels?.[key] : undefined;
};

const setProTargeting = (
  targeting: Targeting,
  advertiserType: string | undefined
): void => {
  if (!advertiserType) {
    return;
  }
  const isPro = advertiserType.toLowerCase() === 'azienda';
  targeting.advertiserType = isPro ? 'pro' : 'freemium';
};

export const getAdvTargeting = () => {
  const recentSearchesRaw = localStorage.getItem(RECENT_SEARCHES_KEY);
  let recentSearches = [];
  try {
    recentSearches = JSON.parse(recentSearchesRaw ?? '[]');
  } catch {
    // invalid JSON
  }

  return targetingForHomepage(recentSearches?.[0]);
};

export const targetingForHomepage = (
  search: RecentSearchOption | undefined
): Targeting => {
  if (!search) {
    return {};
  }
  const { query, adType, category, geo, allFilters } = search;
  const { shp } = allFilters;

  const targeting: Targeting = {};

  // Category:
  const macroCategory = getMacroOfCategoryID(
    category.id as unknown as CategoryID
  );
  targeting.macroCategoryId = macroCategory.id;
  targeting.parentCategoryName = macroCategory?.label;
  targeting.categoryName = category.label;

  // Type:
  targeting.adType = adType as AdTypes;
  const type = buildType(allFilters.filters);
  if (type) {
    targeting.type = type;
  }

  //Advertiser type:
  setProTargeting(
    targeting,
    allFilters.filters?.[filtersMapping.advertiserType]
  );

  //Geo:
  const region =
    geo?.radiusValues?.addressToTrack?.state ?? geo?.geoValues?.region?.value;
  if (region) {
    targeting.region = region;
  }
  const province =
    geo?.radiusValues?.addressToTrack?.county ?? geo?.geoValues?.city?.value;
  if (province) {
    targeting.province = province;
  }
  const town =
    geo?.radiusValues?.addressToTrack?.city ?? geo?.geoValues?.town?.value;
  if (town) {
    targeting.town = town;
  }

  // Query:
  const queryString = query ?? category.label;
  if (queryString) {
    targeting.queryString = queryString;
  }
  targeting.queryIsEmpty = query === undefined;

  targeting.itemShippable = shp ?? false;

  //Price:
  if (allFilters?.filters?.[filtersMapping.priceFrom]) {
    targeting.priceFrom = allFilters?.filters[filtersMapping.priceFrom].replace(
      /\./g,
      ''
    );
  }
  if (allFilters?.filters?.[filtersMapping.priceTo]) {
    targeting.priceTo = allFilters?.filters[filtersMapping.priceTo].replace(
      /\./g,
      ''
    );
  }

  //Automotive:
  if (allFilters?.filters?.[filtersMapping.brand]) {
    targeting.brand = allFilters?.filters[filtersMapping.brand];
  }
  if (allFilters?.filters?.[filtersMapping.model]) {
    targeting.model = allFilters?.filters[filtersMapping.model];
  }
  if (allFilters?.filters?.[filtersMapping.fuel]) {
    targeting.fuel = allFilters?.filters[filtersMapping.fuel];
  }
  if (allFilters?.filters?.[filtersMapping.minRegDate]) {
    targeting.minRegDate = allFilters?.filters[filtersMapping.minRegDate];
  }
  if (allFilters?.filters?.[filtersMapping.maxRegDate]) {
    targeting.maxRegDate = allFilters?.filters[filtersMapping.maxRegDate];
  }
  if (allFilters?.filters?.[filtersMapping.minMileage]) {
    targeting.minMileage = allFilters?.filters[
      filtersMapping.minMileage
    ].replace(/\./g, '');
  }
  if (allFilters?.filters?.[filtersMapping.maxMileage]) {
    targeting.maxMileage = allFilters?.filters[
      filtersMapping.maxMileage
    ].replace(/\./g, '');
  }
  if (allFilters?.filters?.[filtersMapping.carType]) {
    targeting.carType = allFilters?.filters[filtersMapping.carType];
  }
  if (allFilters?.filters?.[filtersMapping.gearbox]) {
    targeting.gearbox = allFilters?.filters[filtersMapping.gearbox];
  }
  if (allFilters?.filters?.[filtersMapping.emission]) {
    targeting.emission = allFilters?.filters[filtersMapping.emission];
  }
  if (allFilters?.filters?.[filtersMapping.vehicleStatus]) {
    targeting.vehicleStatus = allFilters?.filters[filtersMapping.vehicleStatus];
  }
  if (allFilters?.filters?.[filtersMapping.qualitySeal]) {
    targeting.qualitySeal = allFilters?.filters[filtersMapping.qualitySeal];
  }

  // Real estate:
  if (allFilters?.filters?.[filtersMapping.roomMin]) {
    targeting.room = allFilters?.filters[filtersMapping.roomMin];
  } else if (allFilters?.filters?.roomMax) {
    targeting.room = allFilters?.filters.roomMax;
  }

  // Jobs:
  if (allFilters?.filters?.[filtersMapping.jobCategory]) {
    targeting.jobCategory = allFilters?.filters[filtersMapping.jobCategory];
  }
  if (allFilters?.filters?.[filtersMapping.contractType]) {
    targeting.contractType = allFilters?.filters[filtersMapping.contractType];
  }
  if (allFilters?.filters?.[filtersMapping.workLevel]) {
    targeting.workLevel = allFilters?.filters[filtersMapping.workLevel];
  }
  if (allFilters?.filters?.[filtersMapping.degree]) {
    targeting.degree = allFilters?.filters[filtersMapping.degree];
  }

  return targeting;
};
