import type { FC } from 'react';
import { useViewport } from '@sbt-web/hooks';
import { Mobile } from './Mobile';
import { Desktop } from './Desktop';

export const SearchBoxB: FC = () => {
  const { isMobile } = useViewport();

  return isMobile ? <Mobile /> : <Desktop />;
};
