import type { Vertical } from '../../categories';

export const CATEGORY_PLACEHOLDERS: Record<Vertical['key'], string> = {
  motori: 'Vespa, Fiat, Piaggio',
  market: 'iPhone, Camicia, Borsa',
  immobili: 'Bilocale, Villa, Box',
  lavoro: 'Ingegnere, Barista, Commessa',
};

export const VARIANTS = {
  COMPACT: 'compact',
  LINEAR: 'linear',
} as const;
