import React, { useMemo } from 'react';
import Script from 'next/script';
import { LiraRunAdvScript } from '@sbt-web/adv';
import ErrorBoundary from '@sbt-web/error-boundary';
import { HomepageTargetingProps } from '../targeting/forHomepage';
import {
  getDesktopFallbackConfig,
  getMsiteFallbackConfig,
} from '../fallbackConfig';

const LiraWrapper = ({ targeting }: HomepageTargetingProps) => {
  const fallbackConfigDesktop = useMemo(() => getDesktopFallbackConfig(), []);
  const fallbackConfigMsite = useMemo(() => getMsiteFallbackConfig(), []);

  return (
    <LiraRunAdvScript
      NextJsScriptComponent={Script}
      vertical="subito"
      pageType="home"
      env={process.env.NEXT_PUBLIC_INTERNAL_ENVIRONMENT}
      fallbackHebeConfig={{
        msite: fallbackConfigMsite,
        desktop: fallbackConfigDesktop,
      }}
      targeting={targeting}
    />
  );
};

export const LiraRunAdvScriptWrapperForHomepage = (
  props: HomepageTargetingProps
) => {
  return (
    <ErrorBoundary>
      <LiraWrapper {...props} />
    </ErrorBoundary>
  );
};
