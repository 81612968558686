import { type FC, useContext } from 'react';
import cn from 'classnames';
import { clickVerticalTracking } from '../../../../helpers/PulseTracking';
import type { Vertical } from '../../categories';
import { OptimizelySubitoContext } from '@sbt-web/houston-wrapper';

import styles from './index.module.scss';

type Props = {
  vertical: Vertical;
  active?: boolean;
  onClick: (vertical: Vertical) => void;
};

export const VerticalBox: FC<Props> = ({
  active = false,
  vertical,
  onClick,
}) => {
  const { optimizely } = useContext(OptimizelySubitoContext);

  return (
    <button
      className={cn(
        styles['category-box'],
        styles[`category-box-${vertical.key}`],
        { [styles['category-box--active']]: active }
      )}
      data-vertical={vertical.key}
      id={`vertical-${vertical.key}`}
      onClick={() => {
        onClick(vertical);
        clickVerticalTracking(vertical.key);
        optimizely?.track('click_vertical_hp_polaris');
      }}
    >
      <div className={styles['category-box__icon-wrapper']}>
        <img
          src={`/ekhaya/vertical-${vertical.key}.svg`}
          alt={''}
          className={styles['category-box__icon']}
        />
      </div>
      <span className={cn(styles['category-box__label'], 'bold')}>
        {vertical.label}
      </span>
    </button>
  );
};
