import React from 'react';
import { AndromedaClient } from '@sbt-web/networking';
import { PromoteWidget } from './PromoteWidget';

const andromedaClient = new AndromedaClient(
  // Using '/hades' to make a same-domain request, since
  // CORS is not configured.
  '/hades',
  'web',
  1000
);

export const PromoteWidgetWrapper = () => (
  <PromoteWidget
    reservedAreaBase={process.env.NEXT_PUBLIC_RESERVEDAREA_BASE}
    andromedaClient={andromedaClient}
  />
);
