'use client';

import React, { useMemo } from 'react';
import GeoSearch, { type GeoSearchSelection } from '@sbt-web/geo-search';
import type { GeoLocation } from '../SearchBox/navigateToListing';
import {
  clickGeoSearchTracking,
  getTrackingInstance,
} from '../../helpers/PulseTracking';

type Props = {
  location: GeoLocation | undefined;
  onLocationChange: (location: GeoLocation | undefined) => void;
  labelId?: string;
};

export const GeoSearchInput = ({
  labelId,
  location,
  onLocationChange,
}: Props) => {
  const startingValue = useMemo<GeoSearchSelection | undefined>(() => {
    if (!location) return;

    return {
      entry: location.geo,
      searchNearRegions: location?.includeNearbyRegions ?? false,
      responseFrom: 'geogos',
    };
  }, [location]);

  return (
    <GeoSearch
      onDialogOpen={clickGeoSearchTracking}
      assetsBase={process.env.NEXT_PUBLIC_ASSETS_BASE}
      hadesBasePath={process.env.NEXT_PUBLIC_HADES_BASE}
      name="geo-search-field"
      labelId={labelId}
      onSelect={(item) => {
        if (item && item.responseFrom === 'geogos') {
          const { entry, searchNearRegions } = item;
          onLocationChange({
            geo: entry,
            includeNearbyRegions: searchNearRegions,
          });
        }
      }}
      value={startingValue}
      hereApiKey=""
      pulseInstance={getTrackingInstance()}
    />
  );
};
