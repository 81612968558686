'use client';

import React, { useEffect, useState } from 'react';
import { AdItem } from '@sbt-web/network/types';
import { AdHpCarousel } from '../AdHpCarousel';
import ErrorBoundary from '@sbt-web/error-boundary';
import { getRecommendedItems } from './helpers';
import {
  trackCardClick,
  trackCardImpression,
  trackCarouselImpression,
} from './tracking';
import styles from './index.module.scss';

const RecommenderWidget = () => {
  const [items, setItems] = useState<Array<AdItem>>([]);

  // load the Ads
  useEffect(() => {
    getRecommendedItems().then(setItems);
  }, []);

  return (
    <AdHpCarousel
      title={'In evidenza per te'}
      classes={[styles['widget']]}
      items={items.map((ad, index) => ({
        ad,
        onImpression: () => trackCardImpression(ad, index),
        onClick: () => trackCardClick(ad, index),
        onlyChild: items.length === 1,
      }))}
      onImpression={() => trackCarouselImpression(items)}
    />
  );
};

const SafeRecommenderWidget = () => (
  <ErrorBoundary>
    <RecommenderWidget />
  </ErrorBoundary>
);
export { SafeRecommenderWidget as RecommenderWidget };
