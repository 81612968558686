'use client';

import React, { useContext, useEffect, useState } from 'react';
import ErrorBoundary from '@sbt-web/error-boundary';
import { usePublicUser } from '@sbt-web/auth';
import { OptimizelySubitoContext } from '@sbt-web/houston-wrapper';
import { AndromedaClient, ManageAdsItem } from '@sbt-web/networking';
import { trackItemClick, trackView } from './tracking';
import { AdHpCarousel } from '../../AdHpCarousel';
import { ButtonLink } from '@sbt-web/ui';
import style from './index.module.scss';
import { extractUtmFromLocation } from '@sbt-web/utils';

export interface PromoteWidgetProps {
  reservedAreaBase: string;
  andromedaClient: AndromedaClient;
  classes?: Array<string>;
}

export const fetchItems = async (
  andromedaClient: AndromedaClient,
  userId: string
): Promise<Array<ManageAdsItem>> => {
  const MAX_ADS_TO_RETURN = 5;
  const { userAds } = await andromedaClient.getAds(userId, {
    limit: 10,
  });

  if (!userAds || !userAds?.total || userAds?.total > 10) {
    return []; // not eligible
  }
  // return the eligible ads
  return userAds.ads
    .filter((ad) => {
      const hasntAnActiveTransaction = !ad.features['/transaction_status'];
      const hasNoPO = !ad.paidOptions?.length;

      // the ad is eligible if it hasn't an active transaction and it has no PO
      return hasntAnActiveTransaction && hasNoPO;
    })
    .sort((a: ManageAdsItem, z: ManageAdsItem) => {
      const sortByViews =
        (a.statistics?.views ?? 0) - (z.statistics?.views ?? 0);
      if (sortByViews) {
        return sortByViews;
      }

      const sortByMessages =
        (a.statistics?.messages ?? 0) - (z.statistics?.messages ?? 0);
      if (sortByMessages) {
        return sortByMessages;
      }

      return new Date(a.date).getTime() - new Date(z.date).getTime();
    })
    .slice(0, MAX_ADS_TO_RETURN);
};

const Widget = ({
  andromedaClient,
  reservedAreaBase,
  classes = [],
}: PromoteWidgetProps) => {
  const user = usePublicUser();
  const { optimizely } = useContext(OptimizelySubitoContext);
  const [items, setItems] = useState<Array<ManageAdsItem>>([]);
  const [promoteParams, setPromoteParams] = useState<URLSearchParams>(
    new URLSearchParams()
  );

  const fetchData = async (userId: string) => {
    const res = await fetchItems(andromedaClient, userId);
    setItems(res);
    if (res.length) {
      trackView();
    }
  };

  useEffect(() => {
    if (user) {
      fetchData(user.id);
      // prepare the query params for the promote page
      const pageUtm = extractUtmFromLocation();
      pageUtm.append('entry_point', 'HOMEPAGE');
      setPromoteParams(pageUtm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <AdHpCarousel
      title={'Promuovi i tuoi annunci'}
      classes={[style['promote-widget'], ...classes]}
      cardsWithCTA
      items={items.map((ad, index) => {
        const href = `${reservedAreaBase}/annunci/promuovi/${ad.urn}?${promoteParams.toString()}`;
        const onClick = () => {
          trackItemClick(index + 1, ad);
          optimizely?.track('click_promote_hp_polaris');
        };

        return {
          ad,
          onlyChild: items.length === 1,
          href,
          onClick,
          CTA: (
            <ButtonLink
              design="solid-lite"
              size={'small'}
              href={href}
              rel={'nofollow'}
              classes={[style['card__cta']]}
              onClick={onClick}
            >
              Aumenta visibilità
            </ButtonLink>
          ),
        };
      })}
    />
  );
};

export const PromoteWidget = (props: PromoteWidgetProps) => (
  <ErrorBoundary>
    <Widget {...props} />
  </ErrorBoundary>
);
