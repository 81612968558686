import React from 'react';
import styles from './index.module.scss';
import { LiraAdvContainer } from '@sbt-web/adv';
import { LiraContainerIds } from '../Adv/Lira/liraContainerIds';

const AdvTopBanner = () => {
  return (
    <div className={styles['wrapper']}>
      <LiraAdvContainer
        idForAd={LiraContainerIds.aboveTheFold}
        className={styles['banner']}
      />
    </div>
  );
};

export { AdvTopBanner };
