import { type FC, useEffect, useMemo, useState } from 'react';
import type { Vertical } from '../../../categories';
import type { CategoryInfo } from '../../../types';
import { Icon, IconSize, SelectBox } from '@sbt-web/ui';
import { findCategoryById, getCategoryList, isValidCategoryId } from './utils';
import type { CategoryID } from '@sbt-web/network/types';

type Props = {
  name: string;
  vertical: Vertical;
  onChangeCategory: (cat: CategoryInfo) => void;
};

export const CategoriesList: FC<Props> = ({
  name,
  vertical,
  onChangeCategory,
}) => {
  const [categoryId, setCategoryId] = useState<CategoryID | null>(null);

  // Always use the default when the vertical changes or the local value when available.
  const selectedCategoryId =
    categoryId !== null && isValidCategoryId(vertical, categoryId)
      ? categoryId
      : vertical.default.id;

  const categoryList = useMemo(() => getCategoryList(vertical), [vertical.key]);

  const selectedIndex = useMemo(
    () => categoryList.findIndex((cat) => cat.value === selectedCategoryId),
    [categoryList, selectedCategoryId]
  );

  useEffect(() => {
    // Reset local state when the vertical changes.
    setCategoryId(null);
  }, [vertical.key]);

  useEffect(() => {
    const selectedCategory = findCategoryById(vertical, selectedCategoryId);

    if (selectedCategory) {
      onChangeCategory({
        key: selectedCategory.id,
        label: selectedCategory.label,
      });
    }
  }, [vertical.key, selectedCategoryId, onChangeCategory]);

  return (
    <SelectBox
      name={name}
      flavor="large"
      allowGroupSelection
      dropdownHeight="minimal"
      icon={<Icon name="Category" iconSize={IconSize.MD} />}
      valueList={categoryList}
      selectedIndices={selectedIndex >= 0 ? [selectedIndex] : []}
      onSelect={([option]) => {
        if (isValidCategoryId(vertical, option?.value)) {
          setCategoryId(option.value);
        }
      }}
    />
  );
};
