import type { FC } from 'react';
import { type Vertical, Verticals as VerticalsObject } from '../categories';
import { VerticalBox } from './VerticalsBox';

import styles from './index.module.scss';

type Props = {
  activeVertical?: Vertical;
  onItemClick: (vertical: Vertical) => void;
};

export const Verticals: FC<Props> = ({ activeVertical, onItemClick }) => (
  <section className={styles['discover-subito']}>
    {Object.values(VerticalsObject).map((value) => (
      <div key={value.key} className={styles['vertical-wrapper']}>
        <VerticalBox
          vertical={value}
          active={activeVertical === value}
          onClick={onItemClick}
        />
      </div>
    ))}
  </section>
);
