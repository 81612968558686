import React from 'react';
import { CategoryBox, type Category } from './CategoryBox';
import CSSModule from './index.module.scss';

const categories: Category[] = [
  {
    key: 'motori',
    label: 'MOTORI',
    linksGroups: [
      {
        links: [
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/',
            label: 'Auto usate',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/moto-e-scooter/',
            label: 'Moto usate',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/elettrica/',
            label: 'Auto elettriche usate',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/ibrida/',
            label: 'Auto ibride usate',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/diesel/',
            label: 'Auto diesel usate',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/ford/',
            label: 'Ford',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/mercedes/',
            label: 'Mercedes',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/opel/',
            label: 'Opel',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/alfa-romeo/',
            label: 'Alfa Romeo',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/jeep/',
            label: 'Jeep',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/hyundai/',
            label: 'Hyundai',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/toyota/',
            label: 'Toyota',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/moto-e-scooter/?bb=000040',
            label: 'Ducati',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/moto-e-scooter/?bb=000027',
            label: 'Harley Davidson',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/honda/',
            label: 'Honda',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/moto-e-scooter/?bb=000029',
            label: 'Kawasaki',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/moto-e-scooter/?bb=000007',
            label: 'KTM',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/moto-e-scooter/?bb=000053',
            label: 'Piaggio',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/suzuki/',
            label: 'Suzuki',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/moto-e-scooter/?bb=000016',
            label: 'Aprilia',
          },
        ],
      },
      {
        links: [
          {
            isTitle: true,
            url: 'https://www.subito.it/annunci-italia/vendita/auto/fiat/',
            label: 'Fiat',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/fiat/fiat-500/',
            label: 'Fiat 500',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/fiat/panda/',
            label: 'Fiat Panda',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/fiat/doblo/',
            label: 'Fiat Doblo',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/fiat/fiat-punto/',
            label: 'Fiat Punto',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/fiat/seicento/',
            label: 'Fiat 600',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/fiat/tipo/',
            label: 'Fiat Tipo',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/fiat/500l/',
            label: 'Fiat 500l',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/?q=panda+4x4',
            label: 'Fiat Panda 4x4',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/veicoli-commerciali/?q=fiat+scudo',
            label: 'Fiat Scudo',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/veicoli-commerciali/?q=fiat+ducato',
            label: 'Fiat Ducato',
          },
        ],
      },
      {
        links: [
          {
            isTitle: true,
            url: 'https://www.subito.it/annunci-italia/vendita/auto/renault/',
            label: 'Renault',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/renault/captur/',
            label: 'Renault Captur',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/renault/clio/',
            label: 'Renault Clio',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/renault/kadjar/',
            label: 'Renault Kadjar',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/veicoli-commerciali/?q=renault+trafic',
            label: 'Renault Trafic',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/renault/twingo/',
            label: 'Renault Twingo',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/renault/kangoo/',
            label: 'Renault Kangoo',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/renault/scenic/',
            label: 'Renault Scenic',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/renault/4/',
            label: 'Renault 4',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/renault/megane/',
            label: 'Renault Megane',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/renault/zoe/',
            label: 'Renault Zoe',
          },
        ],
      },
      {
        links: [
          {
            isTitle: true,
            url: 'https://www.subito.it/annunci-italia/vendita/auto/peugeot/',
            label: 'Peugeot',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/peugeot/3008/',
            label: 'Peugeot 3008',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/peugeot/208/',
            label: 'Peugeot 208',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/peugeot/2008/',
            label: 'Peugeot 2008',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/peugeot/308/',
            label: 'Peugeot 308',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/peugeot/5008/',
            label: 'Peugeot 5008',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/peugeot/107/',
            label: 'Peugeot 107',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/peugeot/rifter/',
            label: 'Peugeot Rifter',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/peugeot/partner/',
            label: 'Peugeot Partner',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/peugeot/207/',
            label: 'Peugeot 207',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/peugeot/traveller/',
            label: 'Peugeot Traveller',
          },
        ],
      },
      {
        links: [
          {
            isTitle: true,
            url: 'https://www.subito.it/annunci-italia/vendita/auto/bmw/',
            label: 'BMW',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/bmw/x1/',
            label: 'Bmw x1',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/bmw/serie-1/',
            label: 'Bmw serie 1',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/bmw/x3/',
            label: 'Bmw x3',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/moto-e-scooter/?q=bmw+motorrad',
            label: 'Bmw Motorrad',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/bmw/x4/',
            label: 'Bmw x4',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/bmw/z3/',
            label: 'Bmw z3',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/bmw/z4/',
            label: 'Bmw z4',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/bmw/x2/',
            label: 'Bmw x2',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/bmw/serie-3/',
            label: 'Bmw serie 3',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/bmw/x5/',
            label: 'Bmw x5',
          },
        ],
      },
      {
        links: [
          {
            isTitle: true,
            url: 'https://www.subito.it/annunci-italia/vendita/auto/volkswagen/',
            label: 'Volkswagen',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/volkswagen/polo/',
            label: 'Volkswagen Polo',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/volkswagen/tiguan/',
            label: 'Volkswagen Tiguan',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/volkswagen/up/',
            label: 'Volkswagen Up',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/volkswagen/golf/',
            label: 'Volkswagen Golf',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/?q=volkswagen+california',
            label: 'Volkswagen California',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/volkswagen/t-roc/',
            label: 'Volkswagen T roc',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/?q=volkswagen+transporter',
            label: 'Volkswagen Transporter',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/volkswagen/t-cross/',
            label: 'Volkswagen T cross',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/volkswagen/caddy/',
            label: 'Volkswagen Caddy',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/volkswagen/passat/',
            label: 'Volkswagen Passat',
          },
        ],
      },
      {
        links: [
          {
            isTitle: true,
            url: 'https://www.subito.it/annunci-italia/vendita/auto/audi/',
            label: 'Audi',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/audi/a1/',
            label: 'Audi A1',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/audi/a3/',
            label: 'Audi A3',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/audi/q3/',
            label: 'Audi Q3',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/audi/tt/',
            label: 'Audi TT',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/audi/q5/',
            label: 'Audi Q5',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/audi/a4/',
            label: 'Audi A4',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/audi/q2/',
            label: 'Audi Q2',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/audi/a5/',
            label: 'Audi A5',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/audi/a6/',
            label: 'Audi A6',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/auto/?q=audi+q3+sportback',
            label: 'Audi Q3 Sportback',
          },
        ],
      },
    ],
  },
  {
    key: 'market',
    label: 'MARKET',
    linksGroups: [
      {
        links: [
          {
            url: 'https://www.subito.it/annunci-italia/vendita/animali/?q=gatto',
            label: 'Gatto',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/animali/?q=golden+retriever',
            label: 'Golden retriever',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/animali/?q=bulldog+francesi',
            label: 'Bulldog francese',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/animali/?q=cani',
            label: 'Cani',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/animali/?q=cuccioli+border+collie',
            label: 'Border collie',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/animali/?q=labrador',
            label: 'Labrador',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/animali/?q=pitbull',
            label: 'Pitbull',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/biciclette/?q=bici+pieghevole+28',
            label: 'Bici pieghevole',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/biciclette/?q=bici+elettrica+pieghevole',
            label: 'Bici elettrica pieghevole',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/biciclette/?q=bici+da+corsa',
            label: 'Bici da corsa usate',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/biciclette/?q=bicicletta+pedalata+assistita',
            label: 'Bicicletta pedalata assistita',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/biciclette/?q=accessori+bici',
            label: 'Accessori bici',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/biciclette/?q=cyclette+usata',
            label: 'Cyclette usata',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/arredamento-casalinghi/?q=ante+armadio+ikea',
            label: 'Ikea armadi',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/arredamento-casalinghi/?q=scaffali+ikea',
            label: 'Scaffali ikea',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/arredamento-casalinghi/?q=poltrona+frau',
            label: 'Poltrona frau',
          },
          {
            url: 'https://www.subito.it/annunci-lazio/vendita/arredamento-casalinghi/roma/roma/?q=libreria+scolastica',
            label: 'Libreria scolastica',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/arredamento-casalinghi/?q=sedie',
            label: 'Sedie usate',
          },
          {
            url: 'https://www.subito.it/annunci-piemonte/vendita/telefonia/',
            label: 'Cellulari usati',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/telefonia/?q=apple+iphone+usato',
            label: 'Iphone usati',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/telefonia/?q=smartphone',
            label: 'Smartphone usati',
          },
          {
            url: 'https://www.subito.it/annunci-lombardia/vendita/telefonia/milano/milano/',
            label: 'Cellulari usati Milano',
          },
        ],
      },
    ],
  },
  {
    key: 'immobili',
    label: 'IMMOBILI',
    linksGroups: [
      {
        links: [
          {
            url: 'https://www.subito.it/annunci-italia/vendita/appartamenti/',
            label: 'Case in vendita',
          },
          {
            url: 'https://www.subito.it/annunci-italia/affitto/appartamenti/',
            label: 'Case in affitto',
          },
          {
            url: 'https://www.subito.it/annunci-trentino-alto-adige/vendita/appartamenti/bolzano/',
            label: 'Case in vendita Bolzano',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/appartamenti/?q=rent+to+buy',
            label: 'Rent to buy',
          },
          {
            url: 'https://www.subito.it/annunci-lazio/affitto/appartamenti/roma/',
            label: 'Affitto Roma',
          },
          {
            url: 'https://www.subito.it/annunci-lombardia/affitto/appartamenti/milano/',
            label: 'Affitto Milano',
          },
          {
            url: 'https://www.subito.it/annunci-liguria/vendita/appartamenti/genova/',
            label: 'Case in vendita Genova',
          },
          {
            url: 'https://www.subito.it/annunci-emilia-romagna/vendita/appartamenti/bologna/',
            label: 'Case in vendita Bologna',
          },
          {
            url: 'https://www.subito.it/annunci-piemonte/affitto/appartamenti/torino/',
            label: 'Affitti Torino',
          },
          {
            url: 'https://www.subito.it/annunci-lombardia/affitto/appartamenti/milano/?q=affitto',
            label: 'Monolocale affitto Milano',
          },
          {
            url: 'https://www.subito.it/annunci-lombardia/vendita/appartamenti/lodi/',
            label: 'Vendita case Lodi',
          },
          {
            url: 'https://www.subito.it/annunci-sicilia/affitto/appartamenti/palermo/',
            label: 'Affitti Palermo',
          },
          {
            url: 'https://www.subito.it/annunci-trentino-alto-adige/affitto/appartamenti/bolzano/',
            label: 'Affitto Bolzano',
          },
          {
            url: 'https://www.subito.it/annunci-lombardia/vendita/appartamenti/milano/',
            label: 'Appartamenti in vendita Milano',
          },
          {
            url: 'https://www.subito.it/annunci-liguria/affitto/appartamenti/genova/',
            label: 'Affitto Genova',
          },
          {
            url: 'https://www.subito.it/annunci-sicilia/vendita/appartamenti/palermo/palermo/',
            label: 'Appartamenti in vendita a Palermo',
          },
          {
            url: 'https://www.subito.it/annunci-lazio/vendita/appartamenti/roma/',
            label: 'Appartamenti in vendita Roma',
          },
          {
            url: 'https://www.subito.it/annunci-campania/affitto/appartamenti/napoli/afragola/',
            label: 'Affitti Napoli',
          },
          {
            url: 'https://www.subito.it/annunci-piemonte/vendita/appartamenti/torino/torino/',
            label: 'Appartamenti in vendita a Torino',
          },
          {
            url: 'https://www.subito.it/annunci-campania/vendita/appartamenti/napoli/napoli/',
            label: 'Case in vendita Napoli centro',
          },
        ],
      },
    ],
  },
  {
    key: 'lavoro',
    label: 'LAVORO',
    linksGroups: [
      {
        links: [
          {
            url: 'https://www.subito.it/annunci-italia/vendita/offerte-lavoro/',
            label: 'Offerte di lavoro',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/offerte-lavoro/?q=stagione+estiva',
            label: 'Stagione estiva',
          },
          {
            url: 'https://www.subito.it/annunci-lazio/vendita/offerte-lavoro/roma/roma/',
            label: 'Lavoro Roma',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/offerte-lavoro/?q=lavoro+da+casa',
            label: 'Lavoro da casa',
          },
          {
            url: 'https://www.subito.it/annunci-piemonte/vendita/offerte-lavoro/torino/',
            label: 'Offerte lavoro Torino',
          },
          {
            url: 'https://www.subito.it/annunci-lombardia/vendita/offerte-lavoro/milano/',
            label: 'Lavoro Milano',
          },
          {
            url: 'https://www.subito.it/annunci-sicilia/vendita/offerte-lavoro/palermo/palermo/',
            label: 'Lavoro Palermo',
          },
          {
            url: 'https://www.subito.it/annunci-lombardia/vendita/offerte-lavoro/bergamo/',
            label: 'Offerte lavoro Bergamo',
          },
          {
            url: 'https://www.subito.it/annunci-emilia-romagna/vendita/offerte-lavoro/bologna/',
            label: 'Lavoro Bologna',
          },
          {
            url: 'https://www.subito.it/annunci-campania/vendita/offerte-lavoro/napoli/napoli/',
            label: 'Lavoro Napoli',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/offerte-lavoro/?q=online',
            label: 'Lavoro online',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/offerte-lavoro/?q=lavoro+operatore+socio+sanitario',
            label: 'Operatore socio sanitario',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/offerte-lavoro/?q=benzinaio',
            label: 'Benzinaio',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/offerte-lavoro/?q=muratore',
            label: 'Muratore',
          },
          {
            url: 'https://www.subito.it/annunci-lombardia/vendita/offerte-lavoro/lecco/',
            label: 'Lavoro Lecco',
          },
          {
            url: 'https://www.subito.it/annunci-toscana/vendita/offerte-lavoro/pisa/',
            label: 'Lavoro Pisa',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/offerte-lavoro/?q=receptionist',
            label: 'Receptionist',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/offerte-lavoro/?q=lavoro+part+time',
            label: 'Lavoro part time',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/offerte-lavoro/?q=agente+immobiliare',
            label: 'Agente immobiliare',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/offerte-lavoro/?q=assistente+amministrativo',
            label: 'Assistente amministrativo',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/offerte-lavoro/?q=carpentiere',
            label: 'Carpentiere',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/offerte-lavoro/?q=rappresentante',
            label: 'Rappresentante',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/offerte-lavoro/?q=pulizie',
            label: 'Pulizie',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/offerte-lavoro/?q=steward',
            label: 'Steward',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/offerte-lavoro/?q=catena+montaggio',
            label: 'Catena di montaggio',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/offerte-lavoro/?q=segretaria',
            label: 'Segretaria',
          },
          {
            url: 'https://www.subito.it/annunci-lazio/vendita/offerte-lavoro/roma/?q=part+time',
            label: 'Lavoro part time Roma',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/offerte-lavoro/?q=carrozziere',
            label: 'Carrozziere',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/offerte-lavoro/?q=barista',
            label: 'Barista',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/offerte-lavoro/?q=operaio',
            label: 'Operaio',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/offerte-lavoro/?q=cameriere',
            label: 'Cameriere',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/offerte-lavoro/?q=part+time+milano',
            label: 'Lavoro part time Milano',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/offerte-lavoro/?q=badante+convivente',
            label: 'Badante convivente',
          },
          {
            url: 'https://www.subito.it/annunci-lombardia/vendita/offerte-lavoro/milano/?q=baby+sitter',
            label: 'Baby sitter Milano',
          },
          {
            url: 'https://www.subito.it/annunci-lazio/vendita/offerte-lavoro/roma/?q=bada',
            label: 'Badante Roma',
          },
          {
            url: 'https://www.subito.it/annunci-italia/vendita/offerte-lavoro/?q=cameriera+ai+piani',
            label: 'Cameriera ai piani',
          },
          {
            url: 'https://www.subito.it/annunci-lombardia/vendita/offerte-lavoro/milano/?q=stage',
            label: 'Stage Milano',
          },
          {
            url: 'https://www.subito.it/annunci-lombardia/vendita/offerte-lavoro/milano/?q=badante',
            label: 'Badante Milano',
          },
          {
            url: 'https://www.subito.it/annunci-piemonte/vendita/offerte-lavoro/torino/?q=stage',
            label: 'Stage Torino',
          },
        ],
      },
    ],
  },
];

export const CategoriesLinks = () => {
  return (
    <div id="internal-links" className={CSSModule['internal-links']}>
      <div className={CSSModule['internal-links-title']}>Scopri di più</div>
      {categories.map((value) => {
        return <CategoryBox cat={value} key={value.key} />;
      })}
    </div>
  );
};
