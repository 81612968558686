'use client';

import { useLocalStorage } from '@sbt-web/hooks';
import { OptimizelySubitoContext } from '@sbt-web/houston-wrapper';
import { TuttaItalia } from '@sbt-web/networking';
import type { RecentSearchOption } from '@sbt-web/search-autocomplete';
import { AllCategoriesModal } from '@sbt-web/ui';
import { getCategoryByID, queueTask } from '@sbt-web/utils';
import classnames from 'classnames';
import React, { useCallback, useContext, useState } from 'react';
import {
  clickGeoSearchTracking,
  clickSearchBarTracking,
} from '../../../helpers/PulseTracking';
import { Autocomplete } from '../Autocomplete';
import {
  Filters,
  GeoLocation,
  translateParamsToUrl,
} from '../navigateToListing';
import type { CategoryInfo, SearchValue } from '../types';
import { areSameGeo } from '../utils';
import { CategoryID } from '@sbt-web/network/types';
import { RECENT_SEARCHES_KEY } from '@shared/constants';
import { GeoSearchInput } from '../../GeoSearchInput';

import '@sbt-web/geo-search/dist/index.css';
import CSSModule from './index.module.scss';

const DEFAULT_CATEGORY: CategoryInfo = {
  key: CategoryID.Tutte,
  label: 'Tutte le categorie',
};

export const SearchForm = () => {
  const { optimizely } = useContext(OptimizelySubitoContext);
  const [categoryInfo, setCategoryInfo] =
    useState<CategoryInfo>(DEFAULT_CATEGORY);
  const updateCategory = (category?: CategoryInfo) => {
    setCategoryInfo(category ?? DEFAULT_CATEGORY);
  };
  const [query, setQuery] = useState<string | undefined>();
  const [location, setLocation] = useState<GeoLocation | undefined>();
  const [show, setShow] = useState(false);
  const [clothingType, setClothingType] = useState<string>();
  const [forcedSuggestion, setForcedSuggestion] = useState<SearchValue>();

  const [recentSearches, setRecentSearches] = useLocalStorage<
    RecentSearchOption[]
  >(RECENT_SEARCHES_KEY, []);

  const storeRecentSearch = useCallback(
    (recentSearch: RecentSearchOption) => {
      if (
        recentSearch.query === '' &&
        recentSearch?.category?.id === CategoryID.Tutte &&
        recentSearch.geo.geoValues.region.id === TuttaItalia.id
      ) {
        return;
      } else if (!recentSearches?.length) {
        setRecentSearches([recentSearch]);
      } else {
        const olderSearches = recentSearches.filter((olderSearch) => {
          const queryUnequal = olderSearch.query !== recentSearch.query;
          const categoryUnequal =
            olderSearch?.category?.id !== recentSearch?.category?.id;
          const geoUnequal = !areSameGeo(
            olderSearch.geo.geoValues,
            recentSearch.geo.geoValues,
            olderSearch.searchNearRegions,
            recentSearch.searchNearRegions
          );
          return queryUnequal || categoryUnequal || geoUnequal;
        });

        // Put the search at the top as the most recent one and take 2 elements
        setRecentSearches([recentSearch, ...olderSearches].slice(0, 3));
      }
    },
    [recentSearches, setRecentSearches]
  );

  const search = (query?: string) => {
    // if the user didn't select a category it means he never customized the search
    // so we force the suggestion
    if (forcedSuggestion && categoryInfo === DEFAULT_CATEGORY) {
      applySearch(
        forcedSuggestion.query ?? '',
        forcedSuggestion.category ?? DEFAULT_CATEGORY,
        forcedSuggestion.clothingType,
        forcedSuggestion.geo && { geo: forcedSuggestion.geo }
      );
    } else {
      applySearch(query, categoryInfo, clothingType, location);
    }
  };

  const applySearch = (
    query: string | undefined,
    categoryInfo: CategoryInfo,
    clothingType: string | undefined,
    location: GeoLocation | undefined
  ) => {
    const category = getCategoryByID(categoryInfo.key as CategoryID);
    const allFilters = {
      filters: {
        ...(clothingType ? { clt: clothingType } : {}),
      },
      qso: false,
      shp: false,
      urg: false,
    } as const satisfies Filters;
    //Store search in localstorage and updates the recent searches' list
    storeRecentSearch({
      query: query ?? '',
      adType: category.defaultAdType,
      category,
      geo: {
        geoValues: location?.geo ?? { region: TuttaItalia },
        radiusValues: null,
      },
      searchNearRegions: location?.includeNearbyRegions ?? false,
      allFilters,
    });

    optimizely?.track('submit_search_hp_polaris');

    translateParamsToUrl(query, categoryInfo, location, false, allFilters);
  };

  return (
    <div>
      <AllCategoriesModal
        show={show}
        onClose={() => setShow(false)}
        setCategoryInput={(cat) => {
          updateCategory(cat);
        }}
        categoryId={categoryInfo?.key}
        assetsBase={process.env.NEXT_PUBLIC_ASSETS_BASE}
      />
      <form
        name="main-search-form"
        id="main-search-form"
        className={CSSModule['searchbar']}
        action={`${process.env.NEXT_PUBLIC_ENV_ORIGIN}/annunci-italia/vendita/usato`}
        method="GET"
        onSubmit={(e) => {
          e.preventDefault();

          const htmlQuery = document.querySelector<HTMLInputElement>(
            '[name=main-keyword-field]'
          )?.value;

          // If the user searches the text field without selecting a value, the submit comes before the state update
          const calculatedQuery = query === htmlQuery ? query : htmlQuery;

          search(calculatedQuery);
        }}
      >
        <div className={CSSModule['searchbar-col']}>
          <label
            id="main-keyword-label"
            className={classnames(CSSModule['input-label'], 'bold')}
            htmlFor="main-keyword-field"
          >
            Cosa cerchi?
          </label>
          <Autocomplete
            onSelectOption={(info) => {
              if (info.category) {
                updateCategory(info.category);
              }
              setQuery(info.query);
              setClothingType(info.clothingType);
              setLocation(info.geo && { geo: info.geo });
            }}
            onForcedOptionChange={setForcedSuggestion}
          />
        </div>
        <div className={CSSModule['searchbar-col']}>
          <label
            className={classnames(CSSModule['input-label'], 'bold')}
            htmlFor="main-category-selection"
          >
            In quale categoria?
          </label>
          <div className={CSSModule['input']}>
            <img
              src={`${process.env.NEXT_PUBLIC_ASSETS_BASE}/static/icons/cactus/category-squares.svg`}
              width="24"
              height="24"
              alt=""
              className={CSSModule['input-icon']}
            />

            <button
              id="main-category-selection"
              type="button"
              className={classnames(
                CSSModule['category-opener'],
                CSSModule['search-input'],
                CSSModule['arrow-inside'],
                CSSModule['input-inner'],
                CSSModule['main-category-selection']
              )}
              data-vertical="subito"
              onClick={() => {
                setShow(true);

                // Delay even queueing the task in order to prioritise the
                // modal opening.
                queueTask(() => {
                  clickSearchBarTracking();
                }, 'background');
              }}
            >
              {categoryInfo?.label || 'Tutte le categorie'}
            </button>
            <input
              type="hidden"
              value={categoryInfo?.key}
              id="desktop-category-field"
            />
          </div>
        </div>
        <div
          className={classnames(
            CSSModule['searchbar-col'],
            CSSModule['geo-search-container']
          )}
        >
          <label
            className={classnames(CSSModule['input-label'], 'bold')}
            htmlFor="main-location-field"
            id="main-location-label"
          >
            Dove?
          </label>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div
            className={classnames(CSSModule['geo-search-wrapper'])}
            onClick={(e) => clickGeoSearchTracking(e)}
          >
            <GeoSearchInput
              location={location}
              onLocationChange={setLocation}
              labelId="main-location-label"
            />
          </div>
        </div>
        <div className={CSSModule['searchbar-col-lens']}>
          <button type="submit" className={CSSModule['button-icon-lens']}>
            <img src="/ekhaya/lens-white.svg" alt="" width="24" height="24" />
            <span className={classnames('bold')}>Cerca</span>
          </button>
        </div>
      </form>
    </div>
  );
};
