'use client';

import type { FC } from 'react';
import type { Vertical } from '../../categories';
import { VerticalSearchForm } from '../VerticalSearchForm';

import styles from './index.module.scss';

type Props = {
  vertical: Vertical;
};

export const RightModal: FC<Props> = ({ vertical }) => (
  <div className={styles['modal-dx-inner']}>
    <VerticalSearchForm variant="linear" vertical={vertical} />
  </div>
);
