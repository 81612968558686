import React, { ReactElement, useEffect } from 'react';
import cn from 'classnames';
import { AdItem } from '@sbt-web/network/types';
import type { AdTypes, ManageAdsItem } from '@sbt-web/networking';
import { ItemDateLocation, ItemPrice } from '@sbt-web/ui';
import { Thumbnail } from '../Thumbnail';
import { useTrackImpression } from '../useTrackImpression';
import styles from './index.module.scss';

export type AdHpCardProps = {
  ad: AdItem | ManageAdsItem;
  onlyChild?: boolean;
  href?: string;
  onClick?: () => void;
  onImpression?: () => void;
  CTA?: ReactElement;
};

const AdHpCard = ({
  ad,
  onlyChild,
  href,
  onClick,
  onImpression,
  CTA,
}: AdHpCardProps) => {
  const withCTA = !!CTA;

  const componentRef = React.useRef(null);
  const setComponentRef = useTrackImpression({
    enabled: true,
    track: () => {
      onImpression?.();
    },
  });

  useEffect(() => {
    if (componentRef?.current) {
      setComponentRef(componentRef.current);
    }
  }, [setComponentRef, componentRef]);

  return (
    <a
      ref={componentRef}
      href={href ?? ad.urls.default}
      className={cn(styles['card'], {
        [styles['card--no-cta']]: !withCTA,
        [styles['card--only-child']]: onlyChild,
      })}
      onClick={onClick}
    >
      <Thumbnail cssClass={styles['thumbnail']} ad={ad} />

      <div className={styles['info']}>
        <h6 className={`body-text small semibold ${styles['subject']}`}>
          {ad.subject}
        </h6>

        {ad.geo.town?.value && ad.geo.city?.shortName && ad.date && (
          <ItemDateLocation
            gallerized={true}
            date={ad.date}
            town={ad.geo.town.value}
            cityShortName={ad.geo.city.shortName}
            className={styles['geo']}
          />
        )}

        {ad.features['/price'] && (
          <ItemPrice
            classes={[styles['price']]}
            adType={ad.type.key as AdTypes}
            price={Number(ad.features['/price'].values[0].key)}
          />
        )}
      </div>

      {withCTA && <div className={styles['cta']}>{CTA}</div>}
    </a>
  );
};

export { AdHpCard };
