'use client';

import {
  RecentSearchOption,
  SearchAutocomplete,
  SearchAutocompleteOption,
} from '@sbt-web/search-autocomplete';
import '@sbt-web/search-autocomplete/dist/index.css';
import React, { useCallback } from 'react';
import { clickSearchBarTracking } from '../../../helpers/PulseTracking';
import { recentSearch, translateParamsToUrl } from '../navigateToListing';
import type { SearchValue } from '../types';
import { CategoryID } from '@sbt-web/network/types';
import {
  type Category,
  getCategoryByID,
  getMacroOfCategoryID,
} from '@sbt-web/utils';

import CSSModule from './index.module.scss';

const KEYWORD_INPUT_NAME = 'main-keyword-field';

export const getSearchValue = (
  item?: SearchAutocompleteOption | null
): SearchValue => {
  if (!item) {
    return {};
  }

  const query = item.filters?.find((f) => f.uri === '/query/search_text');
  const autocompleteCategory = item.filters?.find((f) => f.uri === '/category');
  const clothingType = item.filters?.find(
    (f) => f.uri === '/fashion/clothing_type'
  )?.values?.[0]?.key;

  let newQueryValue;
  if (query?.values?.[0]?.label) {
    newQueryValue = query.values[0].label;
  }

  let newCategory;
  if (autocompleteCategory?.values?.[0]?.key) {
    const catId = autocompleteCategory.values[0].key as CategoryID;
    const cat = getCategoryByID(catId);
    newCategory = { key: catId, label: cat.label };
  }

  return {
    query: newQueryValue,
    category: newCategory,
    clothingType,
    geo: item.geo,
  };
};

// Fires a search with the parameters of the recent search that has been selected.
export const applyRecentSearch = (recentSearchItem: RecentSearchOption) => {
  recentSearch(
    recentSearchItem.query,
    recentSearchItem.category as Category,
    {
      ...recentSearchItem.geo,
      includeNearbyRegions: recentSearchItem.searchNearRegions,
    },
    recentSearchItem?.allFilters?.shp,
    recentSearchItem?.allFilters
  );
};

export function navigateToListing(info: SearchValue) {
  const clothingType = info.clothingType;
  const allFilters = {
    filters: {
      ...(clothingType ? { clt: clothingType } : {}),
    },
    qso: false,
    shp: false,
    urg: false,
  } as const;
  translateParamsToUrl(
    info.query,
    info.category,
    info.geo && { geo: info.geo },
    false,
    allFilters
  );
}

interface Props {
  onSelectOption: (value: SearchValue) => void;
  onForcedOptionChange?: (value: SearchValue | undefined) => void;
  disableMobileDialog?: boolean;
  disableRecentSearches?: boolean;
  placeholder?: string;
}

const FORCE_REC_CATS: Array<string> = getMacroOfCategoryID(
  CategoryID.Motori
).categories.map((cat) => cat.id);

const FORCE_REC_THRESHOLD = 0.65;

export function Autocomplete({
  onSelectOption,
  onForcedOptionChange,
  disableMobileDialog,
  disableRecentSearches,
  placeholder,
}: Readonly<Props>) {
  const onAutocompleteSelect = (
    item?: SearchAutocompleteOption | null
  ): void => {
    const info = getSearchValue(item);

    // Part of the A/B test for auto in Auto
    if (item?.goToCategoryAuto) {
      navigateToListing(info);
      return;
    }

    onSelectOption(info);
  };

  const handleApplyRecentSearch = (recentSearchItem: RecentSearchOption) => {
    applyRecentSearch(recentSearchItem);
  };

  const handleOnAutoCompleteOptionsChange = useCallback(
    (options: SearchAutocompleteOption[]) => {
      const firstOption = options[0];
      if (isForcedOption(firstOption)) {
        onForcedOptionChange?.(getSearchValue(firstOption));
      } else {
        onForcedOptionChange?.(undefined);
      }
    },
    [onForcedOptionChange]
  );

  return (
    <div className={CSSModule['autocomplete-container']}>
      <SearchAutocomplete
        assetsBase={process.env.NEXT_PUBLIC_ASSETS_BASE}
        hadesBasePath={process.env.NEXT_PUBLIC_HADES_BASE}
        onSelect={onAutocompleteSelect}
        onDialogOpen={clickSearchBarTracking}
        name={KEYWORD_INPUT_NAME}
        labelId="main-keyword-label"
        onRecentSearchSelect={handleApplyRecentSearch}
        onAutocompleteOptionsChange={handleOnAutoCompleteOptionsChange}
        disableMobileDialog={disableMobileDialog}
        disableRecentSearches={disableRecentSearches}
        placeholder={placeholder}
      />
    </div>
  );
}

function isForcedOption(option?: SearchAutocompleteOption): boolean {
  if (!option) {
    return false;
  }

  const { categoryId, probability } = option;

  if (!categoryId || !FORCE_REC_CATS.includes(categoryId)) {
    return false;
  }

  return probability >= FORCE_REC_THRESHOLD;
}
