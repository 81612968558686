import React from 'react';
import CSSModule from './index.module.scss';

export const Logo = function () {
  return (
    <div className={CSSModule['logo-mobile']}>
      <img
        src={`${process.env.NEXT_PUBLIC_ASSETS_BASE}/static/logos/corporate-short.svg`}
        width="136"
        height="32"
        alt=""
      />
    </div>
  );
};
