import { AdItem, AdvertiserType, ItemAdvertiser } from '@sbt-web/network/types';
import { getTrackingInstance } from '../../helpers/PulseTracking';
import { getCategory } from '@sbt-web/tracking';

/**
 * Local storage keys where Orodha stores information about search tracking
 */
const GENERIC_SEARCH_FILTERS_STORAGE_KEY = 'data_generic_search_filters';
const SEARCH_ID_STORAGE_KEY = 'navigation_search_id';

const trackCarouselImpression = (items: Array<AdItem>): void => {
  getTrackingInstance()?.queueEvent({
    type: 'Watch',
    object: {
      '@type': 'RecommendationList',
      '@id': `sdrn:subito:listing:${window.location.href}:element:homepage-recommender`,
      numItems: items.length,
      items: items.map(buildAdItemPayload),
    },
    search: buildSearchPayload(),
  });
};

const trackCardImpression = (adItem: AdItem, rank: number): void => {
  getTrackingInstance()?.queueEvent(
    buildCardEventPayload(adItem, rank, 'Watch')
  );
};

const trackCardClick = (adItem: AdItem, rank: number): void => {
  getTrackingInstance()?.queueEvent(
    buildCardEventPayload(adItem, rank, 'Click')
  );
};

const buildCardEventPayload = (
  adItem: AdItem,
  rank: number,
  eventType: 'Click' | 'Watch'
) => ({
  type: eventType,
  object: {
    ...buildAdItemPayload(adItem),
    'spt:custom': {
      listing_section: 'homepage_recommender',
    },
  },
  search: buildSearchPayload(rank),
});

const buildSearchPayload = (rank?: number) => ({
  '@id': localStorage.getItem(SEARCH_ID_STORAGE_KEY) ?? crypto.randomUUID(),
  '@type': 'SearchMetadata',
  genericSearchFilters: getGenericSearchFiltersFromCache(),
  rank,
  source: 'MainSearch',
});

const getGenericSearchFiltersFromCache = () => {
  const valueFromCache =
    localStorage.getItem(GENERIC_SEARCH_FILTERS_STORAGE_KEY) ?? '[]';
  try {
    return JSON.parse(valueFromCache);
  } catch {
    return [];
  }
};

const buildAdItemPayload = ({
  urn,
  category,
  subject,
  advertiser,
}: AdItem) => ({
  '@type': 'ClassifiedAd',
  '@id': `sdrn:subito:classified:${urn}`,
  category: getCategory(category.id),
  name: subject,
  publisher: buildPublisherPayload(advertiser),
});

const buildPublisherPayload = (advertiser: ItemAdvertiser) => ({
  type: 'Account',
  realm: 'subito',
  id: advertiser.userId,
  accountType:
    advertiser.type === AdvertiserType.Pro ? 'Professional' : 'Private',
});

export {
  GENERIC_SEARCH_FILTERS_STORAGE_KEY,
  SEARCH_ID_STORAGE_KEY,
  trackCarouselImpression,
  trackCardImpression,
  trackCardClick,
};
