import { AdItem, CategoryID, SearchParam } from '@sbt-web/network/types';
import { searchRecommendedHp } from '@sbt-web/network/client';
import type { RecentSearchOption } from '@sbt-web/search-autocomplete';
import { RECENT_SEARCHES_KEY } from '@shared/constants';
import { TuttaItalia } from '@sbt-web/networking';

export type RecommenderSearchParams = Partial<Record<SearchParam, string>>;
export type RawRecommenderSearchParams = Partial<
  Record<SearchParam, string | number | boolean | null | undefined>
>;

const getLastSearchFilters = (): RecommenderSearchParams => {
  const recentSearchesRaw = localStorage.getItem(RECENT_SEARCHES_KEY);
  let recentSearches = [];
  try {
    recentSearches = JSON.parse(recentSearchesRaw ?? '[]');
  } catch {
    // invalid JSON
  }

  return recentSearchToSearchParams(recentSearches?.[0]);
};

/**
 * Converts the given search params bool/number/.. to the string values
 * removing the empty or not defined values
 */
const makeAllStringParams = (
  value: RawRecommenderSearchParams
): RecommenderSearchParams => {
  const res: RecommenderSearchParams = {};

  Object.entries(value).forEach(([key, value]) => {
    const isNullOrUndefined = value === undefined || value === null;
    const isAnEmptyString = value === '';

    if (!isNullOrUndefined && !isAnEmptyString) {
      res[key as SearchParam] = `${value}`;
    }
  });

  return res;
};

/**
 * Converts the recent search read from localStorage to the search params
 * that can be used to call Anubis.
 */
const recentSearchToSearchParams = (
  search: RecentSearchOption | undefined
): RecommenderSearchParams => {
  if (!search) {
    return {};
  }

  const { query, adType, category, geo, searchNearRegions, allFilters } =
    search;
  const { filters, qso, shp, urg } = allFilters;

  return makeAllStringParams({
    [SearchParam.Query]: query,
    [SearchParam.AdType]: adType,
    [SearchParam.Category]:
      category?.id && category?.id !== CategoryID.Tutte
        ? category?.id
        : undefined,
    // geo
    [SearchParam.GeoRegion]:
      geo.geoValues.region.id !== TuttaItalia.id
        ? searchNearRegions && geo.geoValues.region.neighbors
          ? geo.geoValues.region.neighbors
          : geo.geoValues.region.id
        : undefined,
    [SearchParam.GeoCity]: geo.geoValues.city?.id,
    [SearchParam.GeoTown]: geo.geoValues.town?.id,
    [SearchParam.GeoZone]: geo.geoValues.zone?.id,
    // radius
    [SearchParam.Latitude]: geo.radiusValues?.center?.lat,
    [SearchParam.Longitude]: geo.radiusValues?.center?.lng,
    [SearchParam.Radius]: geo.radiusValues?.radiusMeters,
    // other filters
    [SearchParam.ItemShippable]: shp || undefined,
    [SearchParam.ItemUrgent]: urg || undefined,
    [SearchParam.QuerySubjectOnly]: qso || undefined,
    ...filters,
  });
};

/**
 * Loads the Ads which will be show by the carousel of the recommended items
 */
const getRecommendedItems = async (): Promise<Array<AdItem>> => {
  try {
    const { body } = await searchRecommendedHp(
      process.env.NEXT_PUBLIC_HADES_BASE,
      getLastSearchFilters()
    );
    return body.ads;
  } catch {
    return [];
  }
};

export {
  RECENT_SEARCHES_KEY,
  makeAllStringParams,
  getRecommendedItems,
  getLastSearchFilters,
  recentSearchToSearchParams,
};
