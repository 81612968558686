'use client';

import { type FC, useEffect, useRef, useState } from 'react';
import type { Vertical } from '../../categories';
import type { CategoryInfo } from '../../types';
import { GeoLocation, translateParamsToUrl } from '../../navigateToListing';
import { CategoriesList } from './CategoriesList';
import { SubmitButton } from './SubmitButton';
import type { Variant } from './types';
import { CATEGORY_PLACEHOLDERS, VARIANTS } from './constants';
import { GeoSearchInput } from '../../../GeoSearchInput';

import styles from './index.module.scss';

const ANIMATION_ENDED = 30;

type Props = {
  variant: Variant;
  vertical: Vertical;
};

export const VerticalSearchForm: FC<Props> = ({ variant, vertical }) => {
  const [query, setQuery] = useState<string>();

  const [category, setCategory] = useState<CategoryInfo | undefined>({
    key: vertical.default.id,
    label: vertical.default.label,
  });

  const [location, setLocation] = useState<GeoLocation | undefined>();

  const firstInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    window.setTimeout(() => {
      firstInput.current?.focus();
    }, ANIMATION_ENDED);
  }, []);

  return (
    <form
      id="vertical-search-form"
      name="vertical-search-form"
      method="POST"
      onSubmit={(e) => {
        e.preventDefault();
        translateParamsToUrl(query, category, location);
      }}
    >
      <div
        className={
          variant === VARIANTS.COMPACT
            ? styles['compact-form']
            : styles['linear-form']
        }
      >
        <div>
          <label
            className={styles['input-label']}
            htmlFor={`keyword-field-${vertical.key}`}
          >
            Cosa cerchi?
          </label>

          <div className={styles['input']}>
            <img
              src={`/ekhaya/icons/cactus/lens.svg`}
              width="38"
              height="38"
              alt=""
              className={styles['input-icon']}
            />

            <input
              type="text"
              ref={firstInput}
              name="q"
              id={`keyword-field-${vertical.key}`}
              placeholder={CATEGORY_PLACEHOLDERS[vertical.key]}
              onChange={({ target }) => setQuery(target.value)}
              autoComplete="off"
            />
          </div>
        </div>

        <div>
          {variant === VARIANTS.COMPACT && (
            <label
              className={styles['input-label']}
              htmlFor={`category-field-${vertical.key}`}
            >
              Categoria
            </label>
          )}

          <CategoriesList
            name={`category-field-${vertical.key}`}
            vertical={vertical}
            onChangeCategory={setCategory}
          />
        </div>

        <div>
          <label
            className={styles['input-label']}
            htmlFor={`location-field-${vertical.key}`}
          >
            Dove?
          </label>

          <GeoSearchInput
            location={location}
            onLocationChange={setLocation}
            labelId={`location-field-${vertical.key}`}
          />
        </div>

        <SubmitButton variant={variant} vertical={vertical} />
      </div>
    </form>
  );
};
