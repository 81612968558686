import type { SelectOption } from '@sbt-web/ui';
import type { CategoryMinimal, Vertical } from '../../../categories';
import type { CategoryID } from '@sbt-web/network/types';

const createOptionFromCategory = ({
  id,
  label,
}: CategoryMinimal): SelectOption => ({
  type: 'option',
  name: label,
  value: id,
});

const createGroupFromCategory = ({
  id,
  label,
}: CategoryMinimal): SelectOption => ({
  type: 'group',
  name: label,
  value: id,
});

const createOptionsFromSubcategories = (
  subcategories: CategoryMinimal[]
): SelectOption[] => subcategories.map(createOptionFromCategory);

const processParentCategory = (category: CategoryMinimal): SelectOption[] => {
  const result: SelectOption[] = [];

  // Add group header
  result.push(createGroupFromCategory(category));

  // Add subcategories if present
  if (category.categories && category.categories.length > 0) {
    result.push(...createOptionsFromSubcategories(category.categories));
  }

  return result;
};

export const getCategoryList = (vertical: Vertical): SelectOption[] => {
  if (!vertical.categories || vertical.categories.length === 0) {
    return [];
  }

  return vertical.categories.flatMap((category) =>
    Array.isArray(category.categories)
      ? processParentCategory(category)
      : [createOptionFromCategory(category)]
  );
};

export const findCategoryById = (
  vertical: Vertical,
  categoryId?: string
): CategoryMinimal | undefined => {
  if (!categoryId) return undefined;

  // Check if it's the default category
  if (vertical.default.id === categoryId) {
    return vertical.default;
  }

  // Check in top level categories
  const topLevelMatch = vertical.categories.find(
    (cat) => cat.id === categoryId
  );

  if (topLevelMatch) return topLevelMatch;

  // Check in subcategories
  for (const category of vertical.categories) {
    if (!Array.isArray(category.categories)) continue;

    const subCategoryMatch = category.categories.find(
      (subCat) => subCat.id === categoryId
    );

    if (subCategoryMatch) return subCategoryMatch;
  }

  return undefined;
};

export const isValidCategoryId = (
  vertical: Vertical,
  id: unknown
): id is CategoryID => {
  if (typeof id !== 'string') return false;

  return Boolean(findCategoryById(vertical, id));
};
