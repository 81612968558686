import { type FC, useContext, useEffect, useState } from 'react';
import { OptimizelySubitoContext } from '@sbt-web/houston-wrapper';
import { SearchBoxA } from './SearchBoxA';
import { SearchBoxB } from './SearchBoxB';

export const SearchBox: FC = () => {
  const [abTestVariant, setAbTestVariant] = useState('A');

  const { optimizely } = useContext(OptimizelySubitoContext);

  useEffect(() => {
    optimizely?.onReady().then(() => {
      const variant = optimizely.activate('a-dasd');

      if (variant) {
        setAbTestVariant(variant);
      }
    });
  }, [optimizely]);

  switch (abTestVariant) {
    case 'B':
      return <SearchBoxB />;

    case 'A':
    default:
      return <SearchBoxA />;
  }
};
