import { type FC, useState } from 'react';
import type { Vertical } from '../categories';
import { VerticalModal } from '../Verticals/VerticalModal';
import { SearchHeader } from 'components/SearchHeader';
import { Verticals } from '../Verticals';
import { SearchForm } from '../Form';

export const SearchBoxA: FC = () => {
  const [activeVertical, setActiveVertical] = useState<Vertical | null>(null);

  const [showModal, setShowModal] = useState(false);

  const showVertical = (vertical: Vertical) => {
    setActiveVertical(vertical);
    setShowModal(true);
  };

  const closeVertical = () => {
    setActiveVertical(null);
    setShowModal(false);
  };

  return (
    <>
      <SearchHeader>Cosa cerchi?</SearchHeader>

      <SearchForm />

      <Verticals onItemClick={showVertical} />

      {activeVertical && (
        <VerticalModal
          show={showModal}
          vertical={activeVertical}
          onClickClose={closeVertical}
        />
      )}
    </>
  );
};
