import { AdItem } from '@sbt-web/network/types';
import { DeviceWidth } from '@sbt-web/ui';
import styles from './index.module.scss';
import type { ManageAdsItem } from '@sbt-web/networking';

type Props = { ad: AdItem | ManageAdsItem; cssClass?: string };

export type ImageRuleInfo = { name: string; height: number; width: number };
const PLACEHOLDER_SIZE = 40;
const DEFAULT_RULE_MSITE: ImageRuleInfo = {
  name: 'card-mobile-new-small',
  width: 108,
  height: 82,
};
const DEFAULT_RULE_DESKTOP: ImageRuleInfo = {
  name: 'ad-promote-desktop-carousel',
  width: 108,
  height: 126,
};
const LARGE_RULE_DESKTOP: ImageRuleInfo = {
  name: 'ad-promote-desktop-unique',
  width: 202,
  height: 126,
};

const getImageSource = (
  baseSource: string,
  rule: ImageRuleInfo
): { src: string; srcSet: string; width: number; height: number } => {
  const { width, height, name } = rule;
  return {
    width,
    height,
    src: `${baseSource}?rule=${name}-1x-auto`,
    srcSet: `${baseSource}?rule=${name}-1x-auto 1x, ${baseSource}?rule=${name}-2x-auto 2x, ${baseSource}?rule=${name}-3x-auto 3x`,
  };
};

const Thumbnail = ({ ad, cssClass }: Props) => {
  const imgCount = ad.images.length;
  const baseSource = ad.images[0]?.cdnBaseUrl ?? '';
  const msiteImg = getImageSource(baseSource, DEFAULT_RULE_MSITE);
  const desktopImg = getImageSource(baseSource, DEFAULT_RULE_DESKTOP);
  const desktopLargeImg = getImageSource(baseSource, LARGE_RULE_DESKTOP);

  return (
    <div className={`${styles['wrapper']} ${cssClass}`}>
      <figure>
        {baseSource ? (
          <>
            {/* The stylesheet will make visible this image or the picture based
             on the container queries */}
            <img
              className={styles['large-desktop-only']}
              src={desktopLargeImg.src}
              alt={ad.subject}
              srcSet={desktopLargeImg.srcSet}
              // I'm omitting the sizes because this rule has fit "clip"
              loading="lazy"
              decoding="async"
            />
            <picture className={styles['cross-image']}>
              <source
                srcSet={desktopImg.srcSet}
                width={desktopImg.width}
                height={desktopImg.height}
                media={`(min-width: ${DeviceWidth.tablet.min})`}
              />
              <source
                srcSet={msiteImg.srcSet}
                width={msiteImg.width}
                height={msiteImg.height}
                media={`(max-width: ${DeviceWidth.mobile.max})`}
              />
              <img
                // As fallback I'm taking the largest format (also if the ratio is different between desktop and msite)
                src={desktopLargeImg.src}
                alt={ad.subject}
                loading="lazy"
                decoding="async"
              />
            </picture>
          </>
        ) : (
          <img
            src={`${process.env.NEXT_PUBLIC_ASSETS_BASE}/static/icons/categories/${ad.category.id}.svg`}
            alt={ad.subject}
            width={PLACEHOLDER_SIZE}
            height={PLACEHOLDER_SIZE}
            className={styles['placeholder']}
            loading="lazy"
            decoding="async"
          />
        )}
      </figure>
      {imgCount > 1 ? (
        <div className={styles['count']}>
          <img
            alt={`Questo annuncio ha ${imgCount} immagini`}
            src={`${process.env.NEXT_PUBLIC_ASSETS_BASE}/static/icons/cactus/camera.svg`}
            width={16}
            height={16}
            loading="lazy"
            decoding="async"
          />
          <span aria-hidden="true">{imgCount}</span>
        </div>
      ) : null}
    </div>
  );
};

export { Thumbnail };
