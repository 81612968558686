import { useEffect, useState } from 'react';
import { useIsVisible } from '@sbt-web/hooks';

const IMPRESSION_DURATION = 500;
const IMPRESSION_THRESHOLD = 0.01;

const useTrackImpression = ({
  enabled,
  track,
}: {
  enabled: boolean;
  track: () => void;
}): ((el: HTMLElement) => void) => {
  const [impressionTracked, setImpressionTracked] = useState(false);
  const [isItemVisible, setItemRef, unobserveItem] = useIsVisible({
    observerOptions: {
      threshold: IMPRESSION_THRESHOLD,
    },
  });

  useEffect(() => {
    let timeout: number | undefined;
    const resetImpression = () => {
      if (timeout) {
        window.clearTimeout(timeout);
        timeout = undefined;
      }
    };
    if (enabled && isItemVisible && !impressionTracked) {
      // Send tracking Ads Impressions to Pulse
      timeout = window.setTimeout(() => {
        unobserveItem();
        track();
        setImpressionTracked(true);
      }, IMPRESSION_DURATION);
    }

    return resetImpression;
  }, [enabled, isItemVisible, unobserveItem, track, impressionTracked]);

  return setItemRef;
};

export { useTrackImpression, IMPRESSION_DURATION };
