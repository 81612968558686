import {
  CaerusClient,
  HTTPStatusCode,
  type GenericResponse,
} from '@sbt-web/networking';
import { WEB_API_CHANNEL } from '@shared/constants';

const caerusClient: CaerusClient = new CaerusClient(
  process.env.NEXT_PUBLIC_HADES_BASE,
  WEB_API_CHANNEL
);

// CLIENT-SIDE
export const getTransactionSurvey = async (): Promise<GenericResponse> => {
  const res = await caerusClient.getTransactionSurvey();
  if (res.status >= 400) {
    console.warn(`Failed getTransactionSurvey with status ${res.status}`);
  }
  return res;
};

export const setTransactionSurveyCompleted =
  async (): Promise<GenericResponse> => {
    const res = await caerusClient.setTransactionSurveyCompleted();
    if (res.status !== HTTPStatusCode.OK) {
      console.error(
        `Failed setTransactionSurveyCompleted with status ${res.status}`
      );
    }
    return res;
  };
